$colors: (
  "blue": #0088ff,
  "green": #67c23a,
  "grey": #909399,
  "red": #f56c6c,
  "orange": #e6a23c,
  "yellow": #fadb14,
);

$blurBorderColor: #dcdfe6;

$sizes: (
  "small": (
    "height": 30px,
    "fontSize": 14px,
  ),
  "medium": (
    "height": 35px,
    "fontSize": 16px,
  ),
  "large": (
    "height": 40px,
    "fontSize": 18px,
  ),
);

$disableTextColor: #909399;
$disableBgColor: #f5f5f5;
$disableBorderColor: #d9d9d9;

%reset-css {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  outline: none;
  & * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    outline: none;
  }
}
