@import "../_util/SeauiBaseStyle.scss";

button.seaui-button {
  @extend %reset-css;
  cursor: pointer;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  &:hover {
    &::after {
      content: " ";
      width: 100%;
      height: 100%;
      background-image: radial-gradient(#fff 1%, transparent 100%);
      opacity: 0.3;
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  &:focus {
    outline: none;
  }

  &:active {
    &::after {
      display: none;
    }
  }
  @each $name, $color in $colors {
    &.seaui-color-#{$name} {
      border: 1px solid $color;
      background-color: $color;
    }
  }

  &.seaui-text-button {
    padding: 0px 10px;
    border-radius: 3px;
    @each $name, $sizeObj in $sizes {
      @debug $sizeObj;
      &.seaui-size-#{$name} {
        height: map-get($sizeObj, "height");
        font-size: map-get($sizeObj, "fontSize");
      }
    }
  }

  &.seaui-button-icon-on-left {
    i {
      padding-right: 5px;
    }
  }
  &.seaui-button-icon-on-right {
    i {
      padding-left: 5px;
    }
  }

  &.seaui-icon-button {
    //padding: 10px 10px;
    border-radius: 30px;
    @each $name, $sizeObj in $sizes {
      &.seaui-size-#{$name} {
        height: map-get($sizeObj, "height");
        width: map-get($sizeObj, "height");
        font-size: map-get($sizeObj, "fontSize");
      }
    }
  }
  &.seaui-disable {
    cursor: not-allowed;
    border-color: $disableBorderColor;
    background-color: $disableBgColor;
    color: $disableTextColor;
    &:hover {
      &::after {
        display: none;
      }
    }
  }
}
