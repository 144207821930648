@import "../_util/SeauiBaseStyle.scss";
label.seaui-select-option {
  @extend %reset-css;
  padding: 5px 10px;
  min-width: 100%;
  cursor: pointer;
  &:hover {
    background-color: $blurBorderColor;
  }
  &.seaui-select-option-selected {
    color: white;
    @each $name, $color in $colors {
      &.seaui-color-#{$name} {
        background-color: $color;
      }
    }
  }
  &.seaui-disable {
    color: $disableTextColor;
    background-color: $disableBgColor;
    cursor: not-allowed;
  }
}
