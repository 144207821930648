@import "../_util/SeauiBaseStyle.scss";
.seaui-select-wrapper.seaui-select-wrapper {
  @extend %reset-css;
  text-align: center;
  padding: 0px 30px 0px 10px;
  border: 1px solid $blurBorderColor;
  display: inline-block;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
  outline: none;
  > span {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &::after {
    position: absolute;
    display: block;
    content: " ";
    height: 8px;
    width: 8px;
    border-right: 2px solid $blurBorderColor;
    border-bottom: 2px solid $blurBorderColor;
    transform: rotate(45deg);
    top: 28%;
    right: 8px;
  }
  @each $name, $sizeObj in $sizes {
    &.seaui-size-#{$name} {
      height: map-get($sizeObj, "height");
      line-height: map-get($sizeObj, "height");
      font-size: map-get($sizeObj, "fontSize");
    }
  }
  .seaui-select-select-options-wrapper {
    line-height: normal;
    background-color: white;
    min-width: 100%;
    position: absolute;
    left: 0px;
    top: 120%;
    padding: 5px 0px;
    border-radius: 3px;
    box-shadow: 0px 3px 8px 0px $blurBorderColor;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }
  @each $name, $color in $colors {
    &.seaui-color-#{$name} {
      border-color: $color;
      box-shadow: 0px 0px 5px $color;
      //outline: 1px solid $color;
      &::after {
        border-color: $color;
      }
    }
  }
  &.seaui-select-selecting {
    &::after {
      animation: seaui-select-selecting-effect 0.3s ease-in-out;
      animation-fill-mode: forwards;
      top: 40%;
    }
    .seaui-select-select-options-wrapper {
      visibility: visible;
      animation: seaui-select-options-wrapper-effect 0.3s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
  &.seaui-select-after-selecting {
    &::after {
      animation: seaui-select-after-selecting-effect 0.3s ease-in-out;
    }
    .seaui-select-select-options-wrapper {
      visibility: visible;
      animation: seaui-select-options-wrapper-after-selecting-effect 0.3s
        ease-in-out;
      animation-fill-mode: forwards;
    }
  }
  &.seaui-disable {
    cursor: not-allowed;
    color: $disableTextColor;
    border-color: $disableBorderColor;
    background-color: $disableBgColor;
  }
}

@keyframes seaui-select-selecting-effect {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(225deg);
  }
}

@keyframes seaui-select-options-wrapper-effect {
  from {
    top: 60%;
    opacity: 0.1;
  }
  to {
    opacity: 1;
    top: 120%;
  }
}

@keyframes seaui-select-after-selecting-effect {
  from {
    transform: rotate(-135deg);
    top: 40%;
  }
  to {
    transform: rotate(45deg);
    top: 28%;
  }
}

@keyframes seaui-select-options-wrapper-after-selecting-effect {
  from {
    opacity: 0.8;
    top: 120%;
  }
  to {
    top: 60%;
    opacity: 0;
    visibility: hidden;
  }
}
