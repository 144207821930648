@import "../_util/SeauiBaseStyle.scss";

label.seaui-radio-option.seaui-radio-option {
  @extend %reset-css;
  margin-left: 10px;
  font-size: inherit;
  cursor: pointer;
  display: inline-block;

  span {
    vertical-align: middle;
  }

  @each $name, $sizeObj in $sizes {
    &.seaui-size-#{$name} {
      span.seaui-radio-option-icon {
        width: map-get($sizeObj, "fontSize");
        height: map-get($sizeObj, "fontSize");
      }
      &.seaui-radio-option-selected {
        span.seaui-radio-option-icon {
          &::before {
            width: map-get($sizeObj, "fontSize") + 4;
            height: map-get($sizeObj, "fontSize") + 4;
          }
        }
      }
    }
  }

  span.seaui-radio-option-icon {
    border: 1px solid $blurBorderColor;
    display: inline-block;
    border-radius: 50%;

    margin-right: 5px;
    position: relative;
  }

  @each $name, $color in $colors {
    &.seaui-color-#{$name} {
      &:hover {
        span.seaui-radio-option-icon {
          border-color: $color;
          box-shadow: 0px 0px 5px $color;
        }
      }
    }
  }

  &.seaui-radio-option-selected {
    span.seaui-radio-option-icon {
      &::after {
        display: block;
        background-color: white;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        animation-name: seaui-radio-option-selected-effect;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
    }
    @each $name, $color in $colors {
      &.seaui-color-#{$name} {
        color: $color;
        span.seaui-radio-option-icon {
          border: 1px solid $color;
          background-color: $color;
          &::before {
            border: 1px solid $color;
            box-shadow: 0px 0px 5px $color;
          }
        }
      }
    }
    &.seaui-radio-option-selected-effect {
      span.seaui-radio-option-icon {
        &::before {
          content: " ";
          display: block;
          position: relative;
          border-radius: 50%;
          animation-name: seaui-radio-option-border-effect;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
          top: -4px;
          left: -4px;
        }
      }
    }
  }

  &.seaui-disable {
    cursor: not-allowed;
    span {
      color: $disableTextColor;
    }
    &:hover {
      span.seaui-radio-option-icon {
        border-color: $disableBorderColor;
        box-shadow: none;
      }
    }
    &.seaui-radio-option-selected {
      span.seaui-radio-option-icon {
        border: 1px solid $disableBorderColor;
        background-color: $disableBgColor;
        &::after {
          background: $disableTextColor;
        }
      }
    }
  }
}

@keyframes seaui-radio-option-selected-effect {
  from {
    //transform: scale(0.8);
    opacity: 0;
  }
  to {
    //transform: scale(1);
    opacity: 1;
  }
}

@keyframes seaui-radio-option-border-effect {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
