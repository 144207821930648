@import "../_util/SeauiBaseStyle.scss";
div.seaui-slider-wrapper {
  display: inline-block;
  height: 30px;
  width: 200px;
  position: relative;
  opacity: 0.6;
  transition: opacity 0.5s;
  div.seaui-slider-rail {
    height: 3px;
    width: inherit;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    position: absolute;
    top: 50%;
  }

  div.seaui-slider-track {
    height: 3px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
  }
  div.seaui-slider-handle {
    position: absolute;
    top: 50%;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 10px;
    transform: translateY(-40%) translateX(-50%);
  }
  @each $name, $color in $colors {
    &.seaui-color-#{$name} {
      div.seaui-slider-track {
        background-color: $color;
      }
      div.seaui-slider-handle {
        border: 2px solid $color;
        &.seaui-slider-handle-activated {
          box-shadow: $color 0px 0px 3px 2px;
          cursor: pointer;
        }
      }
      &.seaui-slider-activated {
        opacity: 1;
      }
    }
  }
  &:hover {
    opacity: 1;
    div.seaui-slider-rail {
      //background-color: rgba(0, 0, 0, 0.3);
    }
  }
  &.seaui-disable {
    div.seaui-slider-event-mask {
      cursor: not-allowed !important;
    }
  }
}
