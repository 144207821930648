@import "../_util/SeauiBaseStyle.scss";
div.seaui-rate-wrapper {
  @extend %reset-css;
  display: inline-block;
  @each $name, $sizeObj in $sizes {
    &.seaui-size-#{$name} {
      ul {
        height: map-get($sizeObj, "height");
        li {
          .seaui-rate-item-wrapper {
            width: map-get($sizeObj, "fontSize") + 4;
            height: map-get($sizeObj, "fontSize") + 4;
            .seaui-rate-item-left {
              svg {
                width: map-get($sizeObj, "fontSize") + 4;
                height: map-get($sizeObj, "fontSize") + 4;
              }
            }
            .seaui-rate-itme-right {
              svg {
                width: map-get($sizeObj, "fontSize") + 4;
                height: map-get($sizeObj, "fontSize") + 4;
              }
            }
          }
        }
      }
    }
  }
  @each $name, $color in $colors {
    &.seaui-color-#{$name} {
      ul {
        li {
          .seaui-rate-item-wrapper {
            .seaui-rate-item-left {
              svg {
                stroke: $color;
              }
            }
            .seaui-rate-itme-right {
              svg {
                stroke: $color;
              }
            }
            &.seaui-rate-item-whole-star {
              .seaui-rate-item-left {
                svg {
                  fill: $color;
                }
              }
              .seaui-rate-itme-right {
                svg {
                  fill: $color;
                }
              }
            }
            &.seaui-rate-item-half-star {
              .seaui-rate-item-left {
                svg {
                  fill: $color;
                }
              }
            }
          }
        }
      }
    }
  }
  ul {
    display: inline-flex;
    flex-direction: row;
    cursor: pointer;
    gap: 10px;
    align-items: center;
    li {
      list-style: none;
      .seaui-rate-item-wrapper {
        display: flex;
        flex-direction: row;
        .seaui-rate-item-left {
          cursor: pointer;
          height: 100%;
          width: 50%;
          overflow: hidden;
          svg {
            fill: white;
            stroke-width: 3px;
            vertical-align: top;
          }
        }
        .seaui-rate-itme-right {
          cursor: pointer;
          height: 100%;
          width: 50%;
          overflow: hidden;
          svg {
            vertical-align: top;
            stroke-width: 3px;
            fill: white;
            transform: translateX(-50%);
          }
        }
        &.seaui-rate-item-whole-star {
          transform: scale(1.2);
        }
        &.seaui-rate-item-half-star {
          transform: scale(1.2);
        }
        &.seaui-rate-item-wrapper-transition {
          //transition: transform 0.2s;
        }
      }
    }
  }
  &.seaui-disabled {
    * {
      cursor: not-allowed;
    }
  }
}
