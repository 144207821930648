div.seaui-tooltip {
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  position: absolute;
  color: white;
  font-size: 14px;
  transform: translateX(-50%) translateY(-130%);
  display: none;
  &.seaui-tooltip-visible {
    display: block;
  }

  div.seaui-tooltip-content {
    padding: 3px 8px;
    position: relative;
    span.seaui-tooltip-triangle {
      height: 0px;
      width: 0px;
      border: 5px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.75);
      position: absolute;
      left: 50%;
      top: 95%;
      transform: translateX(-50%);
    }
  }
}
