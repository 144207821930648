@import "../_util/SeauiBaseStyle.scss";
%leftRadius {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
%rightRadius {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
div.seaui-input {
  @extend %reset-css;
  display: inline-block;
  @each $name, $sizeObj in $sizes {
    &.seaui-size-#{$name} {
      line-height: map-get($sizeObj, "height");
      height: map-get($sizeObj, "height");
      font-size: map-get($sizeObj, "fontSize");
    }
  }
  > span {
    height: inherit;
    display: inline-block;
    vertical-align: middle;
  }
  span.seaui-input-label-wrapper {
    background-color: #fafafa;
    border: 1px solid $blurBorderColor;
    &.seaui-input-label-position-forward {
      border-right: none;
      @extend %leftRadius;
    }
    &.seaui-input-label-position-behind {
      border-left: none;
      @extend %rightRadius;
    }
    &.seaui-input-text-label {
      padding: 0px 10px;
    }
    &.seaui-input-object-label {
      > * {
        z-index: 900;
        vertical-align: top;
      }
      border: none;
      &.seaui-input-label-position-behind {
        margin-left: -1px;
        > * {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
      &.seaui-input-label-position-forward {
        margin-right: -1px;
        > * {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
  }

  span.seaui-input-wrapper {
    border: 1px solid $blurBorderColor;
    display: inline-block;
    position: relative;
    padding: 0px 10px;
    z-index: 1;
    input {
      padding: 0px 5px;
      border: none;
    }
    @each $name, $color in $colors {
      &.seaui-color-#{$name} {
        i {
          width: 20px;
          color: $color;
        }
        &.seaui-input-onfocus {
          box-shadow: 0px 0px 5px $color;
          border: 1px solid $color;
          z-index: 910;
          i.seaui-input-display-btn {
            visibility: visible;
          }
        }
      }
    }
    i {
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      visibility: hidden;
    }
    &.seaui-input-left-border {
      @extend %leftRadius;
    }
    &.seaui-input-right-border {
      @extend %rightRadius;
    }
  }
  &.seaui-disable {
    span.seaui-input-wrapper {
      cursor: not-allowed;
      background-color: $disableBgColor;
      color: $disableTextColor;
      input {
        background-color: $disableBgColor;
        color: $disableTextColor;
        cursor: not-allowed;
      }
    }
    span.seaui-input-label-wrapper {
      &.seaui-input-text-label {
        background-color: $disableBgColor;
        color: $disableTextColor;
      }
    }
  }
}
