.header {
  width: 1000px;
  margin: 0px auto;
}
.App {
  margin: 0px;
  padding: 0px;
  width: 1000px;
  margin: 0px auto;
  display: flex;
  gap: 60px;
  flex-direction: row;
  & * {
    margin: 0px;
    padding: 0px;
  }
  .menu {
    padding: 20px 0px;
    flex-basis: 180px;
    flex-grow: 0;
    flex-shrink: 0;
    ul {
      li {
        text-align: center;
        list-style: none;
        width: 100%;
        a {
          width: inherit;
          text-align: center;
          display: block;
          padding: 15px 0px;
          text-decoration: none;
          background-color: #eeeeee;
          color: #687980;
          border-bottom: 1px solid #687980;
          font-weight: 900;
          &:hover {
            color: #f7a440;
          }
        }
        &:first-child {
          a {
            border-top: 1px solid #687980;
          }
        }
      }
    }
  }
  .ui-component {
  }
}
