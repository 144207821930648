@import "../_util/SeauiBaseStyle.scss";

div.seaui-radio-wrapper {
  @extend %reset-css;
  display: inline-block;
  @each $name, $sizeObj in $sizes {
    &.seaui-size-#{$name} {
      height: map-get($sizeObj, "height");
      line-height: map-get($sizeObj, "height");
      font-size: map-get($sizeObj, "fontSize");
    }
  }
}
