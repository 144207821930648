div.component-wrapper {
  p {
    padding: 20px 5px;
    font-family: system-ui;
    font-style: italic;
  }
  div {
    padding: 5px;
    display: flex;
    gap: 10px;
  }
  div.btn-size-wrapper {
    align-items: flex-end;
  }
  div.icon-btn-wrapper {
    align-items: center;
  }
}
